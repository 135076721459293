import React from 'react';
import type { FC } from 'react';
import { Link, graphql } from 'gatsby';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Layout } from '../components/layout';
// eslint-disable-next-line import/no-unresolved
import { DoctorPageQuery } from 'graphql-types';
import { SEO } from '../components/seo';

interface PageProps {
  data: DoctorPageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title="獣医師の皆様へ"
      description={`ご予約の方法はこちらになります。予約フォームまたはTEL${
        data.site?.siteMetadata?.tel ?? ''
      }。専用駐車場2台あり`}
      keywords="獣医師の皆様へ"
    />
    <article id="clinical-examination" css={layout}>
      <h2>獣医師の皆様へ</h2>
      <div className="skew-border"></div>
      <section>
        <p>
          犬と猫の腫瘍・がんに対し診断・治療・緩和ケアまで一貫して行うことが可能です
        </p>
      </section>
      <section>
        <h3>〇当院の特徴</h3>
        <ul className="table-container">
          <li className="table-container-items">
            <span className="col1">・迅速なご対応</span>
            <span className="col2">
              <span className="red">最短で当日、遅くとも5日以内</span>
              でのご予約が可能です
            </span>
          </li>
          <li className="table-container-items">
            <span className="col1">・緊急手術</span>
            <span className="col2">
              脾臓破裂・消化管穿孔などの
              <span className="red">緊急手術も対応いたします</span>
            </span>
          </li>
          <li className="table-container-items">
            <span className="col1">・柔軟な連携体制</span>
            <span className="col2">
              「診断のみ」「診断・治療全て」「抗がん剤治療のみ」など、飼い主様とかかりつけ動物病院様のご希望に応じて、
              <span className="red">柔軟な連携体制でご協力いたします</span>
            </span>
          </li>
        </ul>
      </section>
      <section>
        <h3>診療の流れ</h3>
        <ol>
          <li css={stepWrapper}>
            <div css={stepCounter}>STEP.1</div>
            <div css={stepTxt}>
              <p css={title}>
                <span css={emphasize}>予約フォーム</span>またはお電話
              </p>
              <div>
                <Link
                  to="https://forms.gle/bmfva3iKoFHzSgJc8"
                  target="_blank"
                  css={button}
                >
                  獣医師用予約フォーム
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </Link>
              </div>
            </div>
          </li>
          <li css={stepWrapper}>
            <div css={stepCounter}>STEP.2</div>
            <div css={stepTxt}>
              <p css={title}>診察</p>
              <p css={description}>各種検査・生検</p>
              <p css={description}>
                ※CT・MRI検査は近隣の画像センターで実施可能です
              </p>
            </div>
          </li>
          <li css={stepWrapper}>
            <div css={stepCounter}>STEP.3</div>
            <div css={stepTxt}>
              <p css={title}>治療</p>
              <p css={description}>手術・抗がん剤・放射線治療</p>
            </div>
          </li>
          <li css={stepWrapper}>
            <div css={stepCounter}>STEP.4</div>
            <div css={stepTxt}>
              <p css={title}>診療経過のご報告</p>
            </div>
          </li>
          <li css={stepWrapper}>
            <div css={stepCounter}>STEP.5</div>
            <div css={stepTxt}>
              <p css={title}>定期検診</p>
              <p css={description}>かかりつけ動物病院様との連携</p>
            </div>
          </li>
        </ol>
      </section>
      <section>
        <h3>ご案内</h3>
        <p>〇飼い主様へ以下の内容をお伝えください</p>
        <ul>
          <li>
            ・カルテ作成のため、初診の方は予約時間の10分前にお越しください。
          </li>
          <li>
            ・病院入口に<span className="red">専用駐車場が2台</span>
            ございます。駐車場が満車の場合には近隣のコインパーキングをご利用ください。
          </li>
          <li>
            ・かかりつけ動物病院様からのご紹介の場合、
            <span className="red">
              現在服用中のお薬、検査結果などをお持ちください。
            </span>
          </li>
          <li>
            ・当日、
            <span className="red">
              全身麻酔や鎮静での手術、検査を予定している場合
            </span>
            <br />
            食事→前日の夜10時まで。<span className="red">それ以降は絶食</span>
            <br />
            お水→当日の朝8時まで。以上の管理をお願いいたします。
          </li>
        </ul>
      </section>
      <section>
        <h3>診療費について</h3>
        <ul className="table-container">
          <li className="table-container-items">
            <span className="col1">・初診料</span>
            <span className="col2">3,000円</span>
            <span className="col3">診療時間が1時間以上の場合には5,000円</span>
          </li>
          <li className="table-container-items">
            <span className="col1">・各種検査</span>
            <span className="col2">合計10,000円〜30,000円</span>
            <span className="col3"></span>
          </li>
        </ul>
        <p>2023年4月現在</p>
        <p>
          ※上記は目安となります。検査内容や症例の状態により、診療費は変動します。また
          <span className="red">
            診察の際には予想される費用をあらかじめご説明いたします。
          </span>
        </p>
      </section>
    </article>
  </Layout>
);
export default Page;

const layout = css`
  @media (max-width: 560px) {
    padding: 1rem !important; // TODO: index.scss撲滅 撲滅次第importantを削除する
  }
`;

const stepWrapper = css`
  display: flex;
  justify-content: flext-start;
  padding: 10px;
`;

const stepCounter = css`
  width: 10%;
  font-size: 1.1rem;

  @media (max-width: 560px) {
    width: 20%;
  }
`;

const stepTxt = css`
  @media (max-width: 560px) {
    width: 80%;
  }
`;

const title = css`
  font-size: 1.1rem;
`;

const emphasize = css`
  color: #e66a6a;
`;

const button = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  padding: 1em 2em;
  width: 300px;
  color: #024387;
  font-size: 18px;
  font-weight: 700;
  background-color: #cccccc;
  box-shadow: 0 5px 0 #aaaaaa;
  transition: 0.3s;

  &:hover {
    transform: translateY(3px);
    text-decoration: none;
    box-shadow: 0 2px 0 #aaaaaa;
  }

  @media (max-width: 560px) {
    width: 50vw;
    font-size: 14px;
  }
`;

const description = css`
  font-size: 0.9rem;
`;

export const query = graphql`
  query DoctorPage {
    site {
      siteMetadata {
        title
        tel
      }
    }
  }
`;
